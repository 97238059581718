import { Component, OnInit } from '@angular/core';
import { TransactionsService } from '../../../_services/transactions.service';
import { UserService } from '../../../_services/user.service';
import { PlansService } from '../../../_services/plans.service';
import { environment } from '../../../../environments/environment';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  public allRecords;
  public page=1;
  public totalRecords;
  public pageSize;
  public currentPage=1;
  public allUsers;
  public allPlans;
  public user_id = '';
  public plan_id = '';
  public fileName;
  public filePath;

  constructor(
    private _TransactionsService: TransactionsService,
    private _PlansService: PlansService,
    private _UserService: UserService
  ) { }

  ngOnInit(): void {
    this.getRecords();
    this.getAllActiveUsers();
    this.getAllActivePlans();
  }

  getAllActivePlans() {
    startLoader();
    this._PlansService.getAllActiveRecords().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.allPlans = response.data; 
        } else {
          checkTosterResponse(response.message, "Error");
        }
        stopLoader();
      },
      err =>  {stopLoader();},
      () => stopLoader()
    );
  }

  getAllActiveUsers() {
    startLoader();
    this._UserService.getAllActiveRecords().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.allUsers = response.data; 
        } else {
          checkTosterResponse(response.message, "Error");
        }
        stopLoader();
      },
      err =>  {stopLoader();},
      () => stopLoader()
    );
  }

  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getRecords();
    }
  }

  getRecords() {
    startLoader();
    let searchData = {
      currentPage:this.currentPage,
      user_id: this.user_id,
      plan_id: this.plan_id
    };
    console.log(searchData);
    this._TransactionsService.getAll(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){
          console.log(response.data);
          this.allRecords = response.data; 
          this.page = response.pageNo; 
          this.totalRecords = response.totalRecord; 
          this.pageSize = response.pageSize; 
        } else {
          checkTosterResponse(response.message, "Error");
        }
        stopLoader();
      },
      err =>  {stopLoader();},
      () => stopLoader()
    );
  }

  onFilterByUser(key){
    if (key==''){
      this.user_id = '';
    } else {
      this.user_id = key;
    }
    this.getRecords();
  }
  
  onFilterByPlan(key){
    if (key==''){
      this.plan_id = '';
    } else {
      this.plan_id = key;
    }
    console.log(this.plan_id);
    this.getRecords();
  }

  exportDataByFilters() {
    startLoader();
    let searchData = {
      currentPage:this.currentPage,
      user_id: this.user_id,
      plan_id: this.plan_id
    };
    this._TransactionsService.exportDataByFilters(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){  
          this.fileName = response.data;
          this.filePath = environment.apiUrl+'/uploads/'+this.fileName;
          window.open(this.filePath);
        } else {
          checkTosterResponse(response.message, "Error");
        }
        stopLoader();
      },
      err =>  {stopLoader();},
      () => stopLoader()
    );
  } 
  

}
