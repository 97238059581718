import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {

  constructor(private _ApiService: ApiService) { }

  getAll(searchParam) {
    return this._ApiService.post('api/properties/list', searchParam);
  }

  singleRecordByUniqueId(uniqueId) {
    return this._ApiService.get('api/properties/singleRecordByUniqueId/'+ uniqueId);
  }

  getAllImagesRecordsByUniqueId(uniqueId) {
    return this._ApiService.get('api/propertiesimages/getAllImagesRecordsByUniqueId/' + uniqueId);
  }

}
