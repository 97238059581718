import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesService } from '../../../_services/services.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {

  form: FormGroup;
  id: string;
  submitted = false;
  details: {};

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _ServicesService: ServicesService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      isActive: true
    }, {});

    this._ServicesService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){
              this.details = response.data;
              this.form.patchValue(response.data);
            } else {
              checkTosterResponse(response.message, "Error");
            }
            stopLoader();
          },
          (error) => {
            stopLoader();
          }
        );
  }

  onCancel() {
    this._location.back();
  }

  onSubmit() {
    if (this.form.value.title == '') {
      checkTosterResponse("Title is required.", "Error");
      return false;
    }
    this.submitted = true;
    startLoader();
    this.update();
  }

  private update() {
    this._ServicesService.update(this.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            checkTosterResponse(response.message, "Success");
            this._location.back();
          } else {
            checkTosterResponse(response.message, "Error");
          }
          stopLoader();
        },
        error: error => {
          stopLoader();
        }
      });
  }


}
