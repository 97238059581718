import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class StagesService {
  constructor(private _ApiService: ApiService) {}

  getAll(searchParam) {
    return this._ApiService.post("api/stages/list", searchParam);
  }

  getById(id) {
    return this._ApiService.get("api/stages/singleRecord/" + id);
  }

  update(id, data) {
    return this._ApiService.put("api/stages/update/" + id, data);
  }

  delete(id) {
    return this._ApiService.delete("api/stages/delete/" + id);
  }

  create(data) {
    return this._ApiService.post("api/stages/create", data);
  }
  
}
