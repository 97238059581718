import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { BlogsService } from '../../../_services/blogs.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  //selector: 'app-delete',
  //templateUrl: './delete.component.html',
  //styleUrls: ['./delete.component.scss']
})

export class DeleteComponent implements OnInit {
  id: string;

  constructor(
    private route: ActivatedRoute,
    private _BlogsService: BlogsService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    if(window.confirm('Are you sure you want delete?'))
      this.deleteRecord();
    else
      this._location.back();
  }

  deleteRecord() {
    startLoader();
    this._BlogsService.delete(this.id).subscribe(
      (response: any)  => {
        if(response.status==200){
          checkTosterResponse(response.message, "Success");
          this._location.back();
        } else {
          checkTosterResponse(response.message, "Error");
        }
         stopLoader();
      },
      err =>  {stopLoader();},
      () => stopLoader()
    );
  }

}
