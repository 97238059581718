import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../_services/user.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  detailSlug = "Other";
  details: {};
  maxDate: any = new Date();
  minDate = new Date();
  minDate2 = new Date();

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _UserService: UserService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    console.log("maxDate", this.maxDate);
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.form = this.formBuilder.group({
      trial_start_date: ['', Validators.required],
      trial_end_date: ['', Validators.required]
    }, { });

    if (!this.isAddMode) {
      startLoader();
      this._UserService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){
              this.detailSlug = response.data.slug;
              this.details = response.data;
              console.log("response.data.trial_start_date", response.data.trial_start_date);
              // var startDateData = this.convertDateReadFormat(response.data.trial_start_date);
              // var endDateData = this.convertDateReadFormat(response.data.trial_end_date);
              if(response.data.trial_start_date != null) {
                this.form.patchValue({trial_start_date: response.data.trial_start_date});
              }
              if(response.data.trial_end_date != null) {
                this.form.patchValue({trial_end_date: response.data.trial_end_date});
              }
              // this.form.patchValue(response.data);
              // this.form.patchValue({trial_start_date: startDateData});
              // this.form.patchValue({trial_end_date: endDateData});
            } else {
              checkTosterResponse(response.message, "Error");
            }
            stopLoader();
          },
          (error) => {
            stopLoader();
          }
        );
    }
  }

  // convertDateReadFormat(str) {
  //   var date = new Date(str),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [day, mnth, date.getFullYear()].join("/");
  // }

  onCancel() {
    this._location.back();
  }

  onSubmit() {
    if (this.form.value.trial_start_date == '') {
      checkTosterResponse("Trial Start Date is required.","Error");
      return false;
    }
    if (this.form.value.trial_end_date == '') {
      checkTosterResponse("Trial End Date is required","Error");
      return false;
    }
    this.submitted = true;
    startLoader();
    if (this.isAddMode) {
    
    } else {
      this.update();
    }
  }

  convertDateFormat(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  private update() {
    let reqData = {};
    var startDateData = this.convertDateFormat(this.form.value.trial_start_date);
    var endDateData = this.convertDateFormat(this.form.value.trial_end_date);
    if (startDateData) {
      reqData['trial_start_date'] = startDateData;
    }
    if (endDateData) {
      reqData['trial_end_date'] = endDateData;
    }
    // if(startDateData => endDateData) {
    //   checkTosterResponse("trial start date is not correct.","Error");
    //   return false;
    // }
    this._UserService.update(this.id, reqData)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            checkTosterResponse(response.message, "Success");
            this._location.back();
          } else {
            checkTosterResponse(response.message, "Error");
          }
          stopLoader();
        },
        error: error => {
          stopLoader();
        }
      });
  }

  
}
