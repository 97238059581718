import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import { AuthService } from '../_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private _ApiService: ApiService, private _AuthService: AuthService) { }

  login(data) {
    return this._ApiService.post('api/admin/login', data);
  }

  updatePassword(data){
    return this._ApiService.put('api/admin/change-password', data);
  }

  logout() {
    return this._AuthService.removeAuth();
  }

  getAdminProfile(userId) {
    return this._ApiService.get('api/admin/get-admin-profile/'+userId);
  }

  updateProfile(data){
    return this._ApiService.put('api/admin/update-profile', data);
  }

  getDashboardData(){
    return this._ApiService.get('api/admin/getDashboardData');
  }

}
