import { Component, OnInit } from '@angular/core';
import { BlogcategoriesService } from '../../../_services/blogcategories.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  public allRecords;
  public page=1;
  public totalRecords;
  public pageSize;
  public currentPage=1;
  public isActive = '';
  public name = '';

  constructor(
    private _BlogcategoriesService: BlogcategoriesService
  ) { }

  ngOnInit(): void {
    this.getAllRecords();
  }

  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getAllRecords();
    }
  }

  getAllRecords() {
    startLoader();
    let searchData = {
      currentPage:this.currentPage,
      isActive:this.isActive,
      name:this.name,
    };
    this._BlogcategoriesService.getAll(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){
          this.allRecords = response.data; 
          this.page = response.pageNo; 
          this.totalRecords = response.totalRecord; 
          this.pageSize = response.pageSize; 
        } else {
          checkTosterResponse(response.message, "Error");
        }
        stopLoader();
      },
      err =>  {stopLoader();},
      () => stopLoader()
    );
  }

  onFilterByStatus(key){
    if (key==''){
      this.isActive = '';
    } else {
      this.isActive = key;
    }
    this.getAllRecords();
  } 

  onFilterByName(key){
    if (key==''){
      this.name = '';
    } else {
      this.name = key;
    }
    this.getAllRecords();
  } 

}
