import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})

export class ContactusService {

  constructor(private _ApiService: ApiService) { }

  getAll(searchParam) {
    return this._ApiService.post('api/contactus/list', searchParam);
  }

  getById(id) {
    console.log(id);
    return this._ApiService.get('api/contactus/singleRecord/'+ id);
  }

  delete(id) {
    return this._ApiService.delete('api/contactus/delete/' + id);
  }


}
