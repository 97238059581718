import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLogin() {
    if (this.getAuthToken() != null && this.getAuthToken() != '')
      return true;

    return false;
  }

  setAuth(user) {
    localStorage.setItem('auth_token', user.auth_token);
    localStorage.setItem('user_id', user._id);
    localStorage.setItem('user_slug', user.slug);
    localStorage.setItem('user', JSON.stringify(user));
  }

  removeAuth() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_slug');
    localStorage.removeItem('user');
  }

  getAuthToken() {
    return localStorage.getItem('auth_token');
  }

  getUserId() {
    return localStorage.getItem('user_id');
  }

  getUserSlug() {
    return localStorage.getItem('user_slug');
  }

  getUserName() {
    let name;

    try {
      name = this.getUserInfo().first_name + ' ' + this.getUserInfo().last_name;
    } catch (err) {
      name = '';
    }

    return name;
  }

  getUserImage() {
    let image;

    try {
      image = this.getUserInfo().thumbimage ? '' + this.getUserInfo().thumbimage : '';
    } catch (err) {
      image = '';
    }

    return image;
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem('user'));
  }

  updateUserInfo(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

}
