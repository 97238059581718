import { Component, OnInit } from "@angular/core";
import { first } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { ServicesService } from "../../../_services/services.service";

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: "app-view",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
})
export class ViewComponent implements OnInit {
  id: string;
  details: {};

  constructor(
    private route: ActivatedRoute,
    private _ServicesService: ServicesService,
  ) { }

  ngOnInit(): void {
    startLoader();
    this.id = this.route.snapshot.params["id"];
    this._ServicesService.getById(this.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.details = response.data;
          } else {
            checkTosterResponse(response.message, "Error");
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }


}
