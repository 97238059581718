import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PropertycategoriesService } from '../../../_services/propertycategories.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  details: {};

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _PropertycategoriesService: PropertycategoriesService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.form = this.formBuilder.group({
      category_name: ['', Validators.required],
      isActive: true
    }, { });

    if (!this.isAddMode) {
      startLoader();
      this._PropertycategoriesService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){
              this.details = response.data;
              this.form.patchValue(response.data);
            } else {
              checkTosterResponse(response.message, "Error");
            }
            stopLoader();
          },
          (error) => {
            stopLoader();
          }
        );
    }
  }

  onCancel() {
    this._location.back();
  }

  onSubmit() {
    if (this.form.value.category_name == '') {
      checkTosterResponse("Category name is required.","Error");
      return false;
    }
    this.submitted = true;
    startLoader();
    if (this.isAddMode) {
      this.create();
    } else {
      this.update();
    }
  }

  private create() {
    this._PropertycategoriesService.create(this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            checkTosterResponse(response.message, "Success");
            this._location.back();
          } else {
            checkTosterResponse(response.message, "Error");
          }
          stopLoader();
        },
        error: error => {
          stopLoader();
        }
      });
  }

  private update() {
    this._PropertycategoriesService.update(this.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            checkTosterResponse(response.message, "Success");
            this._location.back();
          } else {
            checkTosterResponse(response.message, "Error");
          }
          stopLoader();
        },
        error: error => {
          stopLoader();
        }
      });
  }

  
}
