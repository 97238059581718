import { Component, OnInit } from '@angular/core';
import { ContactusService } from '../../../_services/contactus.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  public allRecords;
  public page=1;
  public totalRecords;
  public pageSize;
  public currentPage=1;

  constructor(
    private _ContactusService: ContactusService
  ) { }

  ngOnInit(): void {
    this.getRecords();
  }

  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getRecords();
    }
  }

  getRecords() {
    startLoader();
    let searchData = {
      currentPage:this.currentPage,
    };
    this._ContactusService.getAll(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){
          console.log(response.data);
          this.allRecords = response.data; 
          this.page = response.pageNo; 
          this.totalRecords = response.totalRecord; 
          this.pageSize = response.pageSize; 
        } else {
          checkTosterResponse(response.message, "Error");
        }
        stopLoader();
      },
      err =>  {stopLoader();},
      () => stopLoader()
    );
  }


}
