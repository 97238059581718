import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})

export class SubscriptionsService {

  constructor(private _ApiService: ApiService) { }

  getAll(searchParam) {
    return this._ApiService.post('api/subscriptions/list', searchParam);
  }

  getById(id) {
    console.log(id);
    return this._ApiService.get('api/subscriptions/singleRecord/'+ id);
  }

  exportDataByFilters(searchParam) {
    return this._ApiService.post('api/subscriptions/exportDataByFilters', searchParam);
  }


}
