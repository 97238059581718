import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';

declare var checkTosterResponse: Function;

@Component({
  selector: 'my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  isAddMode: boolean;
  submitted = false;
  public user;

  constructor(
    private _AuthService:AuthService,
  ) { 
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    
  }
  
}
