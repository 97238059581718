import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../_services/admin.service';
import { AuthService } from '../../_services/auth.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form: FormGroup;
  isAddMode: boolean;
  submitted = false;
  public user;

  constructor(
    private formBuilder: FormBuilder,
    private _AdminService: AdminService,
    private _AuthService:AuthService,
    private _location: Location
  ) { 
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required], 
    }, {

      });
  }

  onCancel() {
    this._location.back();
  }

  onSubmit() {
    if (this.form.value.oldPassword == '') {   
      checkTosterResponse("Please enter old password","Error");
      return false;
    }

    if (this.form.value.newPassword == '') {  
      checkTosterResponse("Please enter new password","Error");
      return false;
    }

    if (this.form.value.confirmPassword == '') {
      checkTosterResponse("Please enter confirm password","Error");
      return false;
    } 

    if(this.form.value.newPassword != this.form.value.confirmPassword ){
      checkTosterResponse("Confirm password didn't matched with the new password","Error");
      return false;
    }

    this.submitted = true;
    startLoader();
    this.update();
  } 

  private update() {
    let data = {
      old_password: this.form.value.oldPassword,
      new_password: this.form.value.newPassword,
      user_id: this.user.id
    }

    this._AdminService.updatePassword(data)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            checkTosterResponse(response.message,"Success");
            this._location.back();
          } else {
            checkTosterResponse(response.message,"Error");
          }  
          stopLoader();
        },
        error: error => {
          stopLoader();
        }
      });
  }

}
