import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BlogsService } from '../../../_services/blogs.service';
import { BlogcategoriesService } from '../../../_services/blogcategories.service';
import { environment } from '../../../../environments/environment';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {
  public environment = environment;
  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  public details;
  dropdownBlogCategories = [];
  image_name = '';

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _BlogcategoriesService: BlogcategoriesService,
    private _BlogsService: BlogsService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.getActiveBlogCategories();

    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      image_name: ['', Validators.required],
      status: ['Active', Validators.required],
      file: [],
      blog_category_id: ['', Validators.required],
      isActive: true
    }, {

      });

    if (!this.isAddMode) {
      startLoader();
      this._BlogsService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){
              this.details = response.data;
              this.image_name = response.data.image_name;
              this.form.patchValue(response.data);
            } else {
              checkTosterResponse(response.message, "Error");
            }
    
            stopLoader();
          },
          (error) => {
            stopLoader();
          }
        );
    }
  }

  getActiveBlogCategories() {
    startLoader();
    this._BlogcategoriesService.getAllActiveRecords().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.dropdownBlogCategories = response.data; 
        } else {
          checkTosterResponse(response.message, "Error");
        }
        
        stopLoader();
      },
      err =>  {stopLoader();},
      () => stopLoader()
    );
  } 

  onCancel() {
    this._location.back();
  }

  onSubmit() {
    if (this.form.value.blog_category_id == '') {
      checkTosterResponse("Blog category id is required","Error");
      return false;
    }
    if (this.form.value.title == '') {
      checkTosterResponse("Title is required.","Error");
      return false;
    }
    if (this.form.value.description == '') {
      checkTosterResponse("Description is required","Error");
      return false;
    }
    if (this.isAddMode) {
      if (this.form.value.file == '' || this.form.value.file == null) {
        checkTosterResponse("Image is required","Error");
        return false;
      }
    }
    this.submitted = true;
    startLoader();
    if (this.isAddMode) {
      this.create();
    } else {
      this.update();
    }
  }

  private create() {
    this._BlogsService.create(this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            checkTosterResponse(response.message, "Success");
            this._location.back();
          } else {
            checkTosterResponse(response.message, "Error");
          }
  
          stopLoader();
        },
        error: error => {
          stopLoader();
        }
      });
  }

  private update() {
    this._BlogsService.update(this.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            checkTosterResponse(response.message, "Success");
            this._location.back();
          } else {
            checkTosterResponse(response.message, "Error");
          }
          stopLoader();
        },
        error: error => {
          stopLoader();
        }
      });
  }

  onFileChange(event){
    const file = event.target.files[0];
    this.form.patchValue({file: file});
  }

  
}
