import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivateGuard } from "./_guard/private.guard";
import { GuestGuard } from "./_guard/guest.guard";

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './admin-pages/login/login.component';
import { ChangePasswordComponent } from './admin-pages/change-password/change-password.component';
import { MyProfileComponent } from './admin-pages/my-profile/my-profile.component';
import { UpdateProfileComponent } from './admin-pages/update-profile/update-profile.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [PrivateGuard] },
  { path: 'admin/login', component: LoginComponent, canActivate: [GuestGuard] },
  { path: 'admin/change-password', component: ChangePasswordComponent, canActivate: [PrivateGuard] },
  { path: 'admin/my-profile', component: MyProfileComponent, canActivate: [PrivateGuard] },
  { path: 'admin/update-profile', component: UpdateProfileComponent, canActivate: [PrivateGuard] },
  // { path: 'admin/logout', component: LogoutComponent, canActivate: [PrivateGuard] },
  { path: 'contactus', loadChildren: () => import('./components/contactus/contactus.module').then(m => m.ContactusModule) },
  { path: 'cmspages', loadChildren: () => import('./components/cmspages/cmspages.module').then(m => m.CmspagesModule) },
  { path: 'staticcontents', loadChildren: () => import('./components/staticcontents/staticcontents.module').then(m => m.StaticcontentsModule) },
  { path: 'faqs', loadChildren: () => import('./components/faqs/faqs.module').then(m => m.FaqsModule) },
  { path: 'plans', loadChildren: () => import('./components/plans/plans.module').then(m => m.PlansModule) },
  { path: 'features', loadChildren: () => import('./components/features/features.module').then(m => m.FeaturesModule) },
  { path: 'services', loadChildren: () => import('./components/services/services.module').then(m => m.ServicesModule) },
  { path: 'emaillogs', loadChildren: () => import('./components/emaillogs/emaillogs.module').then(m => m.EmaillogsModule) },
  { path: 'subscriptions', loadChildren: () => import('./components/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule) },
  { path: 'transactions', loadChildren: () => import('./components/transactions/transactions.module').then(m => m.TransactionsModule) },
  { path: 'stages', loadChildren: () => import('./components/stages/stages.module').then(m => m.StagesModule) },
  { path: 'dealtypes', loadChildren: () => import('./components/dealtypes/dealtypes.module').then(m => m.DealtypesModule) },
  { path: 'blogcategories', loadChildren: () => import('./components/blogcategories/blogcategories.module').then(m => m.BlogcategoriesModule) },
  { path: 'emailtemplates', loadChildren: () => import('./components/emailtemplates/emailtemplates.module').then(m => m.EmailtemplatesModule) },
  { path: 'propertycategories', loadChildren: () => import('./components/propertycategories/propertycategories.module').then(m => m.PropertycategoriesModule) },
  { path: 'blogs', loadChildren: () => import('./components/blogs/blogs.module').then(m => m.BlogsModule) },
  { path: 'properties', loadChildren: () => import('./components/properties/properties.module').then(m => m.PropertiesModule) },

  

  
  
  
  

  // { path: 'roles', loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule) },
  // { path: 'courses', loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule) },
  // { path: 'banners', loadChildren: () => import('./banners/banners.module').then(m => m.BannersModule) },
  // { path: 'students', loadChildren: () => import('./students/students.module').then(m => m.StudentsModule) },
  // { path: 'teachers', loadChildren: () => import('./teachers/teachers.module').then(m => m.TeachersModule) },
  { path: 'users', loadChildren: () => import('./components/users/users.module').then(m => m.UsersModule) },
  // { path: 'libraries', loadChildren: () => import('./libraries/libraries.module').then(m => m.LibrariesModule) },
  // { path: 'batches', loadChildren: () => import('./batches/batches.module').then(m => m.BatchesModule) },
  // { path: 'classes', loadChildren: () => import('./classes/classes.module').then(m => m.ClassesModule) },
  // { path: 'bookings', loadChildren: () => import('./bookings/bookings.module').then(m => m.BookingsModule) },
  // { path: 'doubts', loadChildren: () => import('./doubts/doubts.module').then(m => m.DoubtsModule) },
  // { path: 'tasks', loadChildren: () => import('./tasks/tasks.module').then(m => m.TasksModule) },
  
  // { path: 'transactions', loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule) },
  

  /*
  { path: 'user', loadChildren: () => import('./user-pages/user-pages.module').then(m => m.UserPagesModule)},
  { path: 'basic-ui', loadChildren: () => import('./basic-ui/basic-ui.module').then(m => m.BasicUiModule) },
  { path: 'charts', loadChildren: () => import('./charts/charts.module').then(m => m.ChartsDemoModule) },
  { path: 'forms', loadChildren: () => import('./forms/form.module').then(m => m.FormModule) },
  { path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule) },
  { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
  { path: 'general-pages', loadChildren: () => import('./general-pages/general-pages.module').then(m => m.GeneralPagesModule) },
  { path: 'apps', loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule) },*/
  { path: 'error', loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule) },
  { path: '**', redirectTo: '/error/404'}
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
