import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../_services/auth.service';
import { environment } from '../../../environments/environment';
import { AdminService } from '../../_services/admin.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { GlobalService } from '../../_services/global.service';
import { Subscription } from 'rxjs';

declare var showLogoutPopup: Function;
declare var hideLogoutPopup: Function;
declare var checkTosterResponse: Function;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  public user;
  public userDetails;
  public environment = environment;
  subscription: Subscription;

  constructor(
    private _AdminService: AdminService,
    private _AuthService: AuthService,
    private globalservice: GlobalService,
    private router: Router,
    config: NgbDropdownConfig
  ) {
    config.placement = 'bottom-right';
    this.user = this._AuthService.getUserInfo();
    this.subscription = this.globalservice.refreshUserData.subscribe((res) => {
      this.user = this._AuthService.getUserInfo();
    });
  }

  ngOnInit() {


  }
  openLogoutPopup() {
    showLogoutPopup();
  }
  closeLogoutPopUp() {
    hideLogoutPopup();
  }
  public logout() {
    this._AdminService.logout();
    this.router.navigate(['/admin/login']);
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if ((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if (this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if (this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }


}
