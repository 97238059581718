import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(private _ApiService: ApiService) { }

  getAll(searchParam) {
    return this._ApiService.post('api/cmspages/list', searchParam);
  }

  getById(id) {
    return this._ApiService.get('api/cmspages/singleRecord/'+ id);
  }

  update(id, data) {
      return this._ApiService.put('api/cmspages/update/' + id, data);
  }


}