import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DealtypesService } from '../../../_services/dealtypes.service';
import { environment } from '../../../../environments/environment';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})

export class ViewComponent implements OnInit {
  public environment = environment;
  id: string;
  details: {};
  contentContent: any;

  constructor(
    private route: ActivatedRoute,
    private _DealtypesService: DealtypesService,
  ) { }

  ngOnInit(): void {
    startLoader();
    this.id = this.route.snapshot.params['id'];
    this._DealtypesService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){
              console.log(response.data);
              this.details = response.data;
            } else {
              checkTosterResponse(response.message, "Error");
            }
            stopLoader();
          },
          (error) => {
            stopLoader();
          }
        );
  }

}
