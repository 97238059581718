import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DealtypesService } from '../../../_services/dealtypes.service';
import { environment } from '../../../../environments/environment';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {
  public environment = environment;
  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  details: {};
  image_name = '';

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _DealtypesService: DealtypesService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      image_name: ['', Validators.required],
      file: [],
      isActive: true
    }, { });

    if (!this.isAddMode) {
      startLoader();
      this._DealtypesService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){
              this.details = response.data;
              this.form.patchValue(response.data);
              this.image_name = response.data.image_name;
            } else {
              checkTosterResponse(response.message, "Error");
            }
            stopLoader();
          },
          (error) => {
            stopLoader();
          }
        );
    }
  }

  onCancel() {
    this._location.back();
  }

  onSubmit() {
    if (this.form.value.title == '') {
      checkTosterResponse("Title is required.","Error");
      return false;
    }
    this.submitted = true;
    startLoader();
    if (this.isAddMode) {
    
    } else {
      this.update();
    }
  }

  private update() {
    this._DealtypesService.update(this.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            checkTosterResponse(response.message, "Success");
            this._location.back();
          } else {
            checkTosterResponse(response.message, "Error");
          }
          stopLoader();
        },
        error: error => {
          stopLoader();
        }
      });
  }

  onFileChange(event){
    const file = event.target.files[0];
    this.form.patchValue({file: file});
  }
  
}
