import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PropertiesRoutingModule } from './properties-routing.module';
import { IndexComponent } from './index/index.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from 'ckeditor4-angular';
import { ViewComponent } from './view/view.component';

@NgModule({
  declarations: [IndexComponent, ViewComponent],
  imports: [
    CommonModule,
    PropertiesRoutingModule,
    FormsModule,
    NgbModule,
    CKEditorModule,
    ReactiveFormsModule
  ]
})
export class PropertiesModule { }
