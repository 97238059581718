import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivateGuard } from "../../_guard/private.guard";

import { IndexComponent } from './index/index.component';
import { CreateUpdateComponent } from './create-update/create-update.component';
import { ViewComponent } from './view/view.component';
import { DeleteComponent } from './delete/delete.component';

const routes: Routes = [
  { path: '', redirectTo: 'faqs/index', pathMatch: 'full'},
  { path: 'index', component: IndexComponent, canActivate: [PrivateGuard] },
  { path: 'create', component: CreateUpdateComponent, canActivate: [PrivateGuard] },
  { path: 'view/:id', component: ViewComponent, canActivate: [PrivateGuard] },
  { path: 'delete/:id', component: DeleteComponent, canActivate: [PrivateGuard] },
  { path: 'update/:id', component: CreateUpdateComponent, canActivate: [PrivateGuard] },
  { path: '', component: IndexComponent, canActivate: [PrivateGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FaqsRoutingModule { }
