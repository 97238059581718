import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../_services/user.service";
import { environment } from "../../../../environments/environment";

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.scss"],
})
export class IndexComponent implements OnInit {
  public users;
  public page = 1;
  public totalRecords;
  public pageSize;
  public currentPage = 1;
  public isActive = "";
  public firstName = "";
  public fileName;
  public filePath;

  constructor(private _UserService: UserService) {}

  ngOnInit(): void {
    this.getUsers();
  }

  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getUsers();
    }
  }

  getUsers() {
    startLoader();
    let searchData = {
      currentPage: this.currentPage,
      isActive: this.isActive,
      firstName: this.firstName,
    };
    this._UserService.getAll(searchData).subscribe(
      (response: any) => {
        if (response.status == 200) {
          this.users = response.data;
          this.page = response.pageNo;
          this.totalRecords = response.totalRecord;
          this.pageSize = response.pageSize;
        } else {
          checkTosterResponse(response.message, "Error");
        }

        stopLoader();
      },
      (err) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }

  onFilterByStatus(key) {
    if (key == "") {
      this.isActive = "";
    } else {
      this.isActive = key;
    }
    this.getUsers();
  }

  onFilterByName(key) {
    if (key == "") {
      this.firstName = "";
    } else {
      this.firstName = key;
    }
    this.getUsers();
  }

  exportDataByFilters() {
    startLoader();
    let searchData = {
      currentPage: this.currentPage,
      isActive: this.isActive,
      firstName: this.firstName,
    };
    this._UserService.exportDataByFilters(searchData).subscribe(
      (response: any) => {
        if (response.status == 200) {
          this.fileName = response.data;
          this.filePath = environment.apiUrl + "/uploads/" + this.fileName;
          window.open(this.filePath);
        } else {
          checkTosterResponse(response.message, "Error");
        }
        stopLoader();
      },
      (err) => {
        stopLoader();
      },
      () => stopLoader()
    );
  }
}
