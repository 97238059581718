import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BlogcategoriesRoutingModule } from './blogcategories-routing.module';
import { IndexComponent } from './index/index.component';
import { CreateUpdateComponent } from './create-update/create-update.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from 'ckeditor4-angular';
import { ViewComponent } from './view/view.component';
import { DeleteComponent } from './delete/delete.component';

@NgModule({
  declarations: [IndexComponent, ViewComponent, CreateUpdateComponent, DeleteComponent],
  imports: [
    CommonModule,
    BlogcategoriesRoutingModule,
    FormsModule,
    NgbModule,
    CKEditorModule,
    ReactiveFormsModule
  ]
})
export class BlogcategoriesModule { }
