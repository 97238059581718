import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private _ApiService: ApiService) { }

  getAll() {
    return this._ApiService.get('api/services/list');
  }

  getById(id) {
    return this._ApiService.get('api/services/singleRecord/'+ id);
  }

  update(id, data) {
      return this._ApiService.put('api/services/update/' + id, data);
  }

}
