import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PlansService } from '../../../_services/plans.service';
import { ServicesService } from '../../../_services/services.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  details: {};
  public allServicesRecords;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _PlansService: PlansService,
    private _ServicesService: ServicesService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.form = this.formBuilder.group({
      plan_name: ['', Validators.required],
      service_ids: ['', Validators.required],
      monthly_price: ['', Validators.required],
      // yearly_price: ['', Validators.required],
      isActive: true
    }, { });

    if (!this.isAddMode) {
      this._PlansService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){
              this.details = response.data;
              this.form.patchValue(response.data);
              let servicesIds = response.data.service_ids.split(",");
              this.form.patchValue({service_ids: servicesIds});
            } else {
              checkTosterResponse(response.message, "Error");
            }
            stopLoader();
          },
          (error) => {
            stopLoader();
          }
        );
    }
    this.getAllServices();
  }

  getAllServices() {
    this._ServicesService.getAll().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.allServicesRecords = response.data; 
        } else {
          //checkTosterResponse(response.message, "Error");
        }
      },
      err =>  {}
    );
  }

  onCancel() {
    this._location.back();
  }

  onSubmit() {
    if (this.form.value.plan_name == '') {
      checkTosterResponse("Plan name is required.","Error");
      return false;
    }
    if (this.form.value.monthly_price == '') {
      checkTosterResponse("Monthly price is required","Error");
      return false;
    }
    // if (this.form.value.yearly_price == '') {
    //   checkTosterResponse("Yearly price is required","Error");
    //   return false;
    // }
    this.submitted = true;
    startLoader();
    if (this.isAddMode) {
      //this.create();
    } else {
      this.update();
    }
  }

  // private create() {
  //   this._PlansService.create(this.form.value)
  //     .pipe(first())
  //     .subscribe({
  //       next: (response: any) => {
  //         if(response.status==200){
  //           checkTosterResponse(response.message, "Success");
  //           this._location.back();
  //         } else {
              // checkTosterResponse(response.message, "Error");
  //         }
  
  //         stopLoader();
  //       },
  //       error: error => {
  //         stopLoader();
  //       }
  //     });
  // }

  private update() {
    this._PlansService.update(this.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            checkTosterResponse(response.message, "Success");
            this._location.back();
          } else {
            checkTosterResponse(response.message, "Error");
          }
          stopLoader();
        },
        error: error => {
          stopLoader();
        }
      });
  }

  
}
