import { Component, OnInit } from '@angular/core';
import { PropertiesService } from '../../../_services/properties.service';
import { UserService } from '../../../_services/user.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  public allRecords;
  public page=1;
  public totalRecords;
  public pageSize;
  public currentPage=1;
  public isActive = '';
  public searchKeyword = '';
  public user_id = '';
  public currentOrderType = 'DESC';
  public currentOrderField = 'id';
  public allUsers;

  constructor(
    private _PropertiesService: PropertiesService,
    private _UserService: UserService
  ) { }

  ngOnInit(): void {
    this.getAllRecords();
    this.getAllActiveUsers();
  }
  
  getAllActiveUsers() {
    startLoader();
    this._UserService.getAllActiveRecords().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.allUsers = response.data; 
        } else {
          checkTosterResponse(response.message, "Error");
        }
        stopLoader();
      },
      err =>  {stopLoader();},
      () => stopLoader()
    );
  }

  onFilterByKeyword(key){
    if (key==''){
      this.searchKeyword = '';
    } else {
      this.searchKeyword = key;
    }
    this.getAllRecords();
  }

  sortDataByField(field_name, order_by) {
    this.currentOrderField = field_name;
    if(order_by == "DESC") {
      this.currentOrderType = "ASC";
    }
    if(order_by == "ASC") {
      this.currentOrderType = "DESC";
    }
    this.getAllRecords();
  }

  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getAllRecords();
    }
  }

  getAllRecords() {
   startLoader();
    let searchData = {
      currentPage:this.currentPage,
      isActive:this.isActive,
      currentOrderType:this.currentOrderType,
      currentOrderField:this.currentOrderField,
      user_id: this.user_id,
      searchKeyword:this.searchKeyword,
    };
    this._PropertiesService.getAll(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){
          this.allRecords = response.data; 
          this.page = response.pageNo; 
          this.totalRecords = response.totalRecord; 
          this.pageSize = response.pageSize; 
        } else {
          checkTosterResponse(response.message, "Error");
        }
        stopLoader();
      },
      err =>  {stopLoader();},
      () => stopLoader()
    );
  }

  onFilterByStatus(key){
    if (key==''){
      this.isActive = '';
    } else {
      this.isActive = key;
    }
    this.getAllRecords();
  }
  
  onFilterByUser(key){
    if (key==''){
      this.user_id = '';
    } else {
      this.user_id = key;
    }
    this.getAllRecords();
  }


}
