import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  constructor(private _ApiService: ApiService) { }

  getAll(searchParam) {
    return this._ApiService.post('api/plans/list', searchParam);
  }

  getById(id) {
    return this._ApiService.get('api/plans/singleRecord/'+ id);
  }

  getAllActiveRecords() {
    return this._ApiService.get('api/plans/getAllActiveRecords');
  }

  // create(data) {
  //     return this._ApiService.post('api/plans/create', data);
  // }

  update(id, data) {
      return this._ApiService.put('api/plans/update/' + id, data);
  }

  // delete(id) {
  //     return this._ApiService.delete('api/plans/delete/' + id);
  // }
}
