import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../../../_services/services.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  public allRecords;

  constructor(
    private _ServicesService: ServicesService,
  ) { }

  ngOnInit(): void {
    this.getAllRecords();
  }

  getAllRecords() {
    startLoader();
    this._ServicesService.getAll().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.allRecords = response.data; 
        } else {
          checkTosterResponse(response.message, "Error");
        }
        stopLoader();
      },
      err =>  {stopLoader();},
      () => stopLoader()
    );
  }


}
