import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {AdminService} from '../../_services/admin.service';
import { AuthService } from '../../_services/auth.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  public loginForm: FormGroup;

  constructor(
    private _AdminService: AdminService,
    private _AuthService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder
  ) { 
    
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  public doLogin() {  
    let data = this.loginForm.value;
    if(this.loginForm.value.email==''){
      checkTosterResponse("Email is required","Error");
      return false;
    }

    if(this.loginForm.value.password==''){
      checkTosterResponse("Password is required","Error");
      return false;
    }

    startLoader();
    console.log(data);
    this._AdminService
      .login(data)
      .subscribe(
        (response: any) => {
          if(response.status==200){
            this._AuthService.setAuth(response.data);
            this.router.navigate(['/dashboard']);
            checkTosterResponse(response.message,"Success");
            return false;
          } else {
            checkTosterResponse(response.message,"Error");
            return false;
          }
        },
        (error) => {
          stopLoader();
        }, () => stopLoader()
      );
  }

}
