import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { PropertiesService } from '../../../_services/properties.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})

export class ViewComponent implements OnInit {
  public environment = environment;
  uniqueId: string;
  details: {};
  descriptionContent: any;
  aboutCityContent: any;
  whyCityContent: any;
  notesContent: any;
  public imagesData;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private _PropertiesService: PropertiesService,
  ) { }

  ngOnInit(): void {
    startLoader();
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    this._PropertiesService.singleRecordByUniqueId(this.uniqueId)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){
              console.log(response.data);
              this.details = response.data;
              if(response.data.description) {
                this.descriptionContent = this.sanitizer.bypassSecurityTrustHtml(response.data.description); 
              }
              if(response.data.about_city) {
                this.aboutCityContent = this.sanitizer.bypassSecurityTrustHtml(response.data.about_city); 
              }
              if(response.data.why_city) {
                this.whyCityContent = this.sanitizer.bypassSecurityTrustHtml(response.data.why_city); 
              }
              if(response.data.notes) {
                this.notesContent = this.sanitizer.bypassSecurityTrustHtml(response.data.notes); 
              }
            } else {
              checkTosterResponse(response.message, "Error");
            }
            stopLoader();
          },
          (error) => {
            stopLoader();
          }
        );

     this.getAllImagesByUniqueId();   
  }

  getAllImagesByUniqueId() {
    startLoader();
    this._PropertiesService
      .getAllImagesRecordsByUniqueId(this.uniqueId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.imagesData = response.data;
          } else {
            checkTosterResponse(response.message, 'Error');
          }
          stopLoader();
        },
        (error) => {
          stopLoader();
        }
      );
  }

}
