import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ServicesRoutingModule } from './services-routing.module';
import { IndexComponent } from './index/index.component';
import { CreateUpdateComponent } from './create-update/create-update.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewComponent } from './view/view.component';

@NgModule({
  declarations: [IndexComponent, ViewComponent, CreateUpdateComponent],
  imports: [
    CommonModule,
    ServicesRoutingModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule
  ]
})
export class ServicesModule { }
