import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import { AuthService } from '../_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _ApiService: ApiService, private _AuthService: AuthService) { }

  getAll(searchParam) {
    return this._ApiService.post('api/users/list', searchParam);
  }

  update(id, data) {
    return this._ApiService.put('api/users/update/' + id, data);
}

  getAllActiveRecords() {
    return this._ApiService.get('api/users/getAllActiveRecords');
  }

  getById(id) {
    return this._ApiService.get('api/users/singleRecord/'+ id);
  }

  exportDataByFilters(searchParam) {
    return this._ApiService.post('api/users/exportDataByFilters', searchParam);
  }

  

}
