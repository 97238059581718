import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../_services/admin.service';
import { AuthService } from '../../_services/auth.service';
import { GlobalService } from '../../_services/global.service';

declare var checkTosterResponse: Function;
declare var startLoader: Function;
declare var stopLoader: Function;

@Component({
  selector: 'update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {

  form: FormGroup;
  isAddMode: boolean;
  submitted = false;
  public user;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private _AdminService: AdminService,
    private _AuthService:AuthService,
    private globalservice : GlobalService,
    private _location: Location
  ) { 
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [this.user.name, Validators.required],
      email: [this.user.email, Validators.required]
    }, {

      });
  }

  onCancel() {
    this._location.back();
  }

  onSubmit() {
    if (this.form.value.name == '') {   
      checkTosterResponse("Please enter name","Error");
      return false;
    }

    if (this.form.value.email == '') {  
      checkTosterResponse("Please enter email","Error");
      return false;
    }

    this.submitted = true;
    startLoader();
    this.update();
  } 

  private update() {
    let data = {
      name: this.form.value.name,
      email: this.form.value.email,
      user_id: this.user.id
    }

    this._AdminService.updateProfile(data)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            this.getUserProfileDetails();
          } else {
            checkTosterResponse(response.message,"Error");
          }  
          stopLoader();
        },
        error: error => {
          stopLoader();
        }
      });
  }

  private getUserProfileDetails() {
    this._AdminService.getAdminProfile(this.user.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){
              this._AuthService.updateUserInfo(response.data); 
              this.globalservice.refreshUserData.next(true);
              checkTosterResponse(response.message,"Success");
              this.router.navigate(['/admin/my-profile']);
            } else {
              checkTosterResponse(response.message,"Error");
            }
            stopLoader();
          },
          (error) => {
            stopLoader();
          }
        );
  }


}
