import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SubscriptionsRoutingModule } from './subscriptions-routing.module';
import { IndexComponent } from './index/index.component';
import { ViewComponent } from './view/view.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [IndexComponent, ViewComponent],
  imports: [
    CommonModule,
    SubscriptionsRoutingModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule
  ]
})
export class SubscriptionsModule { }
