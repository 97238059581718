import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class BlogsService {

  constructor(private _ApiService: ApiService) { }

  getAll(searchParam) {
    return this._ApiService.post('api/blogs/list', searchParam);
  }

  getById(id) {
    return this._ApiService.get('api/blogs/singleRecord/'+ id);
  }

  create(data) {
      return this._ApiService.post('api/blogs/create', data);
  }

  update(id, data) {
      return this._ApiService.put('api/blogs/update/' + id, data);
  }

  delete(id) {
      return this._ApiService.delete('api/blogs/delete/' + id);
  }
}
