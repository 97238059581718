import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AlertModule } from './_alert';
import { ContactusModule } from './components/contactus/contactus.module';
import { LoginComponent } from './admin-pages/login/login.component';
import { CmspagesModule } from './components/cmspages/cmspages.module';
import { StaticcontentsModule } from './components/staticcontents/staticcontents.module';
import { StagesModule } from './components/stages/stages.module';
import { FaqsModule } from './components/faqs/faqs.module';
import { BlogsModule } from './components/blogs/blogs.module';
import { PropertiesModule } from './components/properties/properties.module';
import { PlansModule } from './components/plans/plans.module';
import { FeaturesModule } from './components/features/features.module';
import { ServicesModule } from './components/services/services.module';
import { EmaillogsModule } from './components/emaillogs/emaillogs.module';
import { SubscriptionsModule } from './components/subscriptions/subscriptions.module';
import { TransactionsModule } from './components/transactions/transactions.module';
import { DealtypesModule } from './components/dealtypes/dealtypes.module';
import { BlogcategoriesModule } from './components/blogcategories/blogcategories.module';
import { EmailtemplatesModule } from './components/emailtemplates/emailtemplates.module';
import { PropertycategoriesModule } from './components/propertycategories/propertycategories.module';



// import { RolesModule } from './roles/roles.module';
// import { CoursesModule } from './courses/courses.module';
// import { StudentsModule } from './students/students.module';
// import { TeachersModule } from './teachers/teachers.module';
import { UsersModule } from './components/users/users.module';
// import { LibrariesModule } from './libraries/libraries.module';
// import { BatchesModule } from './batches/batches.module';
// import { ClassesModule } from './classes/classes.module';
// import { BannersModule } from './banners/banners.module';
// import { BookingsModule } from './bookings/bookings.module';
// import { DoubtsModule } from './doubts/doubts.module';
// import { TasksModule } from './tasks/tasks.module';


import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { ChangePasswordComponent } from './admin-pages/change-password/change-password.component';
import { AppComponent } from './app.component';

import { ContentAnimateDirective } from './shared/directives/content-animate.directive';

import { MyProfileComponent } from './admin-pages/my-profile/my-profile.component';
import { UpdateProfileComponent } from './admin-pages/update-profile/update-profile.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    LoginComponent,
    MyProfileComponent,
    UpdateProfileComponent,
    ChangePasswordComponent,
    ContentAnimateDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PropertiesModule,
    TransactionsModule,
    NgbModule,
    StaticcontentsModule,
    ServicesModule,
    AngularMultiSelectModule,
    SubscriptionsModule,
    BrowserAnimationsModule,
    AlertModule,
    FormsModule,
    EmaillogsModule,
    FaqsModule,
    PlansModule,
    ReactiveFormsModule,
    ChartsModule,
    HttpClientModule,
    BlogsModule,
    // RolesModule,
    BlogcategoriesModule,
    PropertycategoriesModule,
    DealtypesModule,
    FeaturesModule,
    StagesModule,
    EmailtemplatesModule,
    // ClassesModule,
    // BannersModule,
    // BookingsModule,
    // DoubtsModule,
    // TasksModule,
    ContactusModule,
    // TransactionsModule,
    CmspagesModule,
    // CoursesModule,
    // LibrariesModule,
    // StudentsModule,
    // TeachersModule,
    UsersModule,
    // BatchesModule,
  ],
  providers: [ThemeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
